<script lang="ts">
import uDarkLogo from '@/assets/u-logo-dark.png';
import DesenvolvedorGateway from '@/infra/gateway/desenvolvedorGateway';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import GeneralGateway from '@/infra/gateway/generalGateway';
import modalService from '@/services/ModalService';
import { inject } from 'vue';
import { getOffset, getParams, clearParams } from '@/services/Browser';
import { authentication } from '@/services/Authentication';

export default {
  name: "BaseHeader",
  props: {
    showBackground: { type: Boolean, default: false },
    moveHeaderLinks: { type: Boolean, defaut: false },
    displayUHeader: { type: Boolean, defaut: false },
    userAccess: { type: Object, default: undefined },
    showUrbeLogo: { type: Boolean, defaut: false },
    isLoading: { type: Boolean, default: true },
  },
  data() {

    return {
      uDarkLogo,
      getOffset,
      getParams,
      clearParams
    };
  },
  methods: {
    async abrirModal(tipo: string) {

      if (tipo == "cadastro_incorporador") {
        window.location.href = "https://incorporador.urbe.me/login"
        return;
      }

      const modalTipo = [
        'login',
        'cadastro_investidor',
        'cadastro_investidor_pj',
        'cadastro_investidor',
        'cadastro_incorporador'
      ]
      if (modalTipo.includes(tipo)) {
        await modalService.modalLoginCadastro(
          tipo,
          {
            investidor: this.investidorGateway,
            desenvolvedor: this.desenvolvedorGateway,
            general: this.generalGateway,
          }
        );
        this.clearParams('modal');
      }
    },
    checkLocation(route: string) {
      if (window.location.pathname == '/ajuda/') {
        const element = document.getElementById(route);
        if (!element) return;
        const top = this.getOffset(element).top;
        window.scroll({
          top: top,
          behavior: 'smooth'
        });
        return;
      }
      this.$router.push('/ajuda/#' + route)
    },
    logout() {
      authentication.logout()
    }
  },
  setup() {
    return {
      investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
      desenvolvedorGateway: inject<DesenvolvedorGateway>('desenvolvedorGateway')!,
      generalGateway: inject<GeneralGateway>('generalGateway')!,
    }
  },
  mounted() {
    const modalType = getParams('modal');
    if (modalType) {
      this.abrirModal(modalType);
      setTimeout(() => {
        clearParams('modal');
      }, 1);
    }
  }
};
</script>

<template>
  <nav class="navbar-urbe z-50 px-4 py-4 min-h-[6rem] fixed top-0 right-0 left-0 flex items-center" :class="{
    'bg-white': showBackground,
    'bg-transparent': !showBackground,
  }">
    <div class="ctn home-container flex">
      <a class="navbar-brand flex items-center mr-[1rem]" :class="showUrbeLogo ? 'opacity-1' : 'opacity-0'"
        id="navbar-brand" href="/">
        <img class="max-h-[3.03rem]" :src="uDarkLogo" alt="Urbe" />
      </a>
      <div class="basis-auto w-full ml-[3rem] hidden md:flex">
        <ul id="nav-list" class="flex mr-auto gap-6 mr-auto" :class="{
          'translate-x-[0rem]': moveHeaderLinks,
          'translate-x-[-9rem]': !moveHeaderLinks
        }">
          <li class="lg:h-[5rem] flex items-center">
            <router-link to="/investidor">
              <a class="p-[1rem] hover-line text-shadow-hover cursor-pointer">Quero Investir</a>
            </router-link>
          </li>
          <li class="lg:h-[5rem] flex items-center">
            <router-link to="/incorporador">
              <a class="p-[1rem] text-shadow-hover cursor-pointer">Quero Captar</a>
            </router-link>
          </li>
          <li class="lg:h-[5rem] flex items-center dropdown dropdown-anim nav-item">
            <router-link to="/ajuda">
              <a class="p-[1rem] dropdown-toggle nav-link cursor-pointer" role="button" aria-haspopup="true"
                aria-expanded="false" id="navbarDropdown" data-toggle="dropdown">Ajuda</a>
            </router-link>
            <div
              class="dropdown-menu hidden absolute dark-blue-opaco-bg top-[101%] p-[.6rem] font-medium font-small rounded-lg text-slate-100 w-[18rem]"
              aria-labelledby="navbarDropdown" role="menu">
              <a @click="checkLocation('perguntas-frequentes')"
                class="w-full cursor-pointer px-4 py-1 hover:text-white">Perguntas frequentes</a>
              <a @click="checkLocation('termos-de-uso')" class="w-full cursor-pointer px-4 py-1 hover:text-white">Termos
                de uso</a>
              <a @click="checkLocation('politica-de-privacidade')"
                class="w-full cursor-pointer px-4 py-1 hover:text-white">Política de privacidade</a>
              <a @click="checkLocation('fale-conosco')" class="w-full cursor-pointer px-4 py-1 hover:text-white">Fale
                conosco</a>
            </div>

          </li>
          <li class="lg:h-[5rem] flex items-center">
              <a href="https://urbe.me/lab/" target="_blank" class="p-[1rem] hover-line text-shadow-hover cursor-pointer">urbe.lab</a>
          </li>
        </ul>

        <ul class="flex gap-6 items-center">
          
          <template v-if="userAccess">
            <template v-if="isLoading">
            </template>
            <template v-if="userAccess?.adminJuridico">  
              <li v-if="userAccess?.admin" class="lg:h-[5rem] flex items-center">
                <a href="/administracao/" class="w-full cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2 text-shadow-hover">Acesso Jurídico</a>
              </li>
              <li v-if="userAccess?.incorporadora" class="lg:h-[5rem] flex items-center">
                <a href="/dashboard/incorporador/"
                  class="w-full cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2 text-shadow-hover">Painel do
                  Incorporador</a>
              </li>
              <li @click="logout" class="lg:h-[5rem] flex items-center">
                  <a class="w-full cursor-pointer px-4 py-1 text-shadow-hover">Sair</a>
              </li>
            </template>
            <template v-if="!userAccess?.adminJuridico">  
              <li v-if="userAccess?.admin || userAccess?.adminFinanceiro" class="lg:h-[5rem] flex items-center">
                <a :href="!userAccess?.adminFinanceiro ? '/administracao/' : '/administracao/financeiro/financeiro_corporate/' " class="w-full cursor-pointer px-4 py-1 text-shadow-hover">Admin</a>
              </li>
              <li v-if="userAccess?.incorporadora && !userAccess?.adminFinanceiro" class="lg:h-[5rem] flex items-center">
                <a href="/dashboard/incorporador/"
                  class="w-full cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2 text-shadow-hover">Painel do
                  Incorporador</a>
              </li>
              <template v-if="userAccess?.usuario">
                <li v-if="!userAccess?.adminFinanceiro" class="lg:h-[5rem] flex items-center">
                  <a href="/dashboard/investidor/"
                  class="w-full cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2 text-shadow-hover">Painel do
                  Investidor</a>
                </li>
                <li @click="logout" class="lg:h-[5rem] flex items-center">
                  <a class="w-full cursor-pointer px-4 py-1 text-shadow-hover">Sair</a>
                </li>
              </template>
              <template v-else>
                <li class="lg:h-[5rem] flex items-center text-[1.6rem] font-normal">
                  <button @click="abrirModal('login')"
                  class="p-[0.25rem_0.5rem] cursor-pointer text-shadow-hover">&nbsp;&nbsp;Login&nbsp;&nbsp;</button>
                </li>
                
                <li class="lg:h-[5rem] flex items-center cursor-pointer dropdown">
                  <button
                  class="btn-outline-urbe dropdown-toggle cadastro-bottom text-[1.6rem] font-normal text-white-shadow-hover">&nbsp;&nbsp;Cadastro&nbsp;&nbsp;</button>
                  <div
                  class="dropdown-menu hidden absolute dark-blue-opaco-bg top-[82%] p-[.6rem] font-medium font-small rounded-lg text-slate-100">
                  <a class="w-full cursor-pointer px-4 py-1 hover:text-white"
                  @click="abrirModal('cadastro_investidor')">Investidor Individual</a>
                  <a class="w-full cursor-pointer px-4 py-1 hover:text-white"
                  @click="abrirModal('cadastro_investidor_pj')">Investidor Institucional</a>
                  <a class="w-full cursor-pointer px-4 py-1 hover:text-white"
                  @click="abrirModal('cadastro_incorporador')">Incorporador</a>
                </div>
              </li>
              </template>
            </template>

          </template>

          <template v-else>
            <div v-for="index in 2" class="skeleton w-[15rem] max-h-[3rem] py-[1.5rem] rounded-[4rem]">
            </div>
          </template>
        </ul>
      </div>

    </div>

  </nav>
</template>