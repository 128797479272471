<script lang="ts">
import urbemeWhiteLogo from '@/assets/urbeme_white.svg';
import byOspaLogo from '@/assets/byospa.svg';
import bancoCentralImg from '@/assets/bancocentral.png';
import socinalImg from '@/assets/socinal.png';
import { getOffset } from '@/services/Browser';
import {goToUrl} from '@/services/Browser';


export default {
    name: "BaseFooter",
    data() {
        return {
            urbemeWhiteLogo,
            byOspaLogo,
            bancoCentralImg,
            socinalImg,

            getOffset,
            goToUrl,
        };
    },
    methods: {
        checkLocation(route: string){
        if(window.location.pathname == '/ajuda/') {
          const element = document.getElementById(route);
          if(!element)return;
          const top = this.getOffset(element).top;
          window.scroll({
              top: top,
              behavior: 'smooth'
          });
        } else {
            this.$router.push('/ajuda/#'+route)
        }
        }
    },
}

</script>

<template>
    <section class="dark-blue-bg text-[1.4rem] white-text pb-1">
        <div class="ctn home-container">

            <div class="flex gap-[1.5rem] py-[3rem] items-end">
                <img :src="urbemeWhiteLogo" class="w-[18.5rem] h-[3.7rem]" alt="Logo Urbe">
                <a href="https://pt.ospa.com.br/" target="_blank" class="mb-[0.5rem]"><img :src="byOspaLogo" alt="By OSPA" class="align-middle"></a>
            </div>

            <div class="flex flex-wrap justify-between text-[1.6rem]">
                <div class="flex flex-col basis-3/6 grow-0 shrink-0 gap-[1rem] md:flex-none md:gap-[0.2rem]">
                    <h3 class="font-medium text-[2rem] mx-0 my-[1rem]">Sobre</h3>
                    <a  class="hover:underline pointer" href="https://pt.ospa.com.br/" target="_blank">A OSPA</a>
                    <a class="hover:underline pointer"  href="https://pt.ospa.com.br/work-with-us" target="_blank">Carreiras</a>
                </div>
                <div class="flex flex-col basis-3/6 grow-0 shrink-0 gap-[1rem] md:flex-none md:gap-[0.2rem]">
                    <h3 class="font-medium text-[2rem] mx-0 my-[1rem]">Transparência</h3>
                    
                    <a  class="hover:underline pointer" @click="checkLocation('perguntas-frequentes')" target="_blank">Perguntas frequentes</a>
                    <a  class="hover:underline pointer" @click="checkLocation('termos-de-uso')" target="_blank">Termos de uso</a>
                    <a  class="hover:underline pointer" @click="checkLocation('politica-de-privacidade')" target="_blank">Política de privacidade</a>
                </div>
                <div class="flex flex-col basis-3/6 grow-0 shrink-0 gap-[1rem] md:flex-none md:gap-[0.2rem]">
                    <h3 class="font-medium text-[2rem] mx-0 my-[1rem]">Redes sociais</h3>
                    <a  class="hover:underline pointer" href="https://www.instagram.com/ospa.urbe.me/" target="_blank">Instagram</a>
                    <a  class="hover:underline pointer" href="https://www.youtube.com/c/URBEME/channels" target="_blank">YouTube</a>
                    <a  class="hover:underline pointer" href="https://br.linkedin.com/company/urbemebrasil" target="_blank">LinkedIn</a>
                </div>
                <div class="flex flex-col basis-3/6 grow-0 shrink-0 gap-[1rem] md:flex-none md:gap-[0.2rem] contato">
                    <h3 class="font-medium text-[2rem] mx-0 my-[1rem]">Contato</h3>

                    
                    <p>
                        <a class="hover:underline pointer" @click="checkLocation('fale-conosco')" target="_blank">Fale conosco</a><br>
                    </p>
                </div>
            </div>

            <div class="flex flex-col items-start mt-[1.5rem]">
                <h6 class="text-[1.6rem]">A URBE.ME</h6>
                <p class="text-justify grey-text">A URBE.ME SERVIÇOS DESENVOLVIMENTO URBANO, sociedade
                    empresária limitada, inscrita no CNPJ sob o nº 21.013.359/0001-73, com sede na Avenida Osvaldo Aranha,
                    790/Sala 302, Bom Fim, Porto Alegre, Rio Grande do Sul - CEP 90.035-191, URBE.ME, é uma plataforma
                    digital que atua como correspondente bancária de instituições financeiras para a realização de operações
                    de crédito nos termos da Resolução CMN nº. 4.935 de 29 de julho de 2021 do Banco Central do Brasil.
                    Sendo assim, a URBE.ME não é uma instituição financeira e não realiza operações de financiamento,
                    investimentos ou quaisquer outras operações privativas de instituições financeiras diretamente, de modo
                    que quem realiza as operações de crédito é a SOCINAL S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO,
                    inscrita no CNPJ sob nº 03.881.423/0001-56, com sede na Avenida Brasil, nº 10/4º andar, Centro,
                    Araruama/RJ, CEP 28970-000, cuja ouvidoria atende pelo telefone 0800 282 4193 ou pelo endereço de e-mail
                    ouvidoria@socinal.com.br.</p>
            </div>

            <div class="flex flex-wrap justify-between items-start mt-[3rem]">
                <div class="flex items-center gap-[3rem]">
                    <h6 class="font-normal text-[1.6rem]">Regulados pelo</h6>
                    <a href="https://www.bcb.gov.br/" target="_blank" class="text-center">
                        <img :src="bancoCentralImg" alt=""
                            class="img-responsive center-block" style="max-height: 60px;">
                    </a>
                </div>
                <div class="flex items-center  gap-[3rem]">
                    <h6 class="font-normal text-[1.6rem]">Correspondentes bancários</h6>
                    <div>
                        <a href="https://www.socinal.com.br/" target="_blank" class="text-center">
                            <img :src="socinalImg" alt=""
                                class="img-responsive center-block" style="max-height: 60px;">
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap justify-between pt-[0.5rem] my-[3rem]">
            <p class='pb-2 pb-md-0'>Copyright &copy; 2014-{{ new Date().getFullYear() }} <a href="https://urbe.me">URBE.ME</a>. All
                rights reserved.</p>
            <p>&copy; URBE.ME - Av. Osvaldo Aranha 790, Porto Alegre - RS</p>
        </div>

    </div>
    </section>

</template>

