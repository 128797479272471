<script lang="ts">
import {inject} from 'vue';
import BaseHeader from '@/Components/BaseHeader.vue';
import BaseFooter from '@/Components/BaseFooter.vue';
import MobileHeader from '@/Components/MobileHeader.vue';
import MobileMenu from '@/Components/MobileMenu.vue'
import Hijacker from '@/Components/Hijacker.vue'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { isMobile, isMobileApp } from '@/services/Mobile';
import { getOffset } from '@/services/Browser';
import GeneralGateway from '@/infra/gateway/generalGateway';
import { authentication } from '@/services/Authentication';


export default {
    name: "App",
    components : {
        BaseHeader,
        BaseFooter,
        MobileHeader,
        MobileMenu,
        Hijacker
    },

    data() {
        const isCalculadora = `${this.$router.currentRoute.value.fullPath.includes('calculadora/rating') ? '' : 'overflow-x-hidden'}`

        return {
            showNavBackground : false,
            showUrbeLogo: false,
            moveHeaderLinks: false,
            headerLogo: false,
            showMobileMenu: false,

            userAccess: undefined as Object | undefined,
            isLoading: true,

            mobile: isMobile(),
            mobileApp: isMobileApp(),
            getOffset,
        }
    },
    methods: {
        setMobileMenu (){
            this.showMobileMenu = !this.showMobileMenu;
        },
        setVideoOpacity(scroll: number) {
            const backgroundElement = document.getElementsByClassName("landing-background")[0]
            if(backgroundElement) (backgroundElement as HTMLElement).style.opacity = `${1 - scroll/window.innerHeight}`;
        },
        setElementOpacity(element: HTMLElement, value: string){
            element.style.opacity = value;
        },
        setNavBarStatus(navbar: any){
            this.showNavBackground = this.moveHeaderLinks = this.getOffset(navbar).top > 10;
        },
        setHeader(){
            this.showUrbeLogo = this.showNavBackground = this.moveHeaderLinks = true;
        },
        handleScroll () {
            if(window.location.pathname != "/"){
                this.setHeader();  
                return;
            }
            const windowOffSet = window?.top?.scrollY
            const logo = document.getElementById("logo-home-u");
            const navbar = document.getElementsByClassName("navbar-urbe")[0];
            const navbrand = document.getElementById("navbar-brand");

            if(this.mobile) return;
            
            navbar && this.setNavBarStatus(navbar);

            if(logo && navbrand && windowOffSet){
                if((this.getOffset(logo).top  - windowOffSet) > 26 ){
                    this.setElementOpacity(logo, "1")
                    this.showUrbeLogo = false;
                }
                else{
                    this.setElementOpacity(logo, "0")
                    this.showUrbeLogo = true;
                }
            }

            windowOffSet && this.setVideoOpacity(windowOffSet);
        },
        setMobileResize(){this.mobile = isMobile()},

        async getUserAccess(){
            this.userAccess = authentication.getUserAccess()
            const response = await this.generalGateway.getUserAccess();
            this.userAccess = response;
            authentication.setUserAccess(this.userAccess);
            this.isLoading = false;
        },
    },
    setup() {
        return {
            generalGateway: inject<GeneralGateway>('generalGateway')!,
        }
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    async created() {
        await this.getUserAccess();
    },
    updated(){
        if(window.location.pathname != "/") this.setHeader();
    },
    async mounted() { 
        if(window.location.pathname != "/") this.setHeader();
        window.addEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.setMobileResize)
        AOS.init()
    },
    computed: {
        showTemplate() {
            return !this.$route.meta.noTemplate
        },
        authorization() {
            return this.userAccess;
        }
    }
}
</script>


<template>
    <div v-if="!isLoading" class="w-full min-[769px]:pt-[6rem] lg:pt-[7rem]">
        <template v-if="showTemplate">
            <template v-if="!mobile">
                <BaseHeader  
                    :show-urbe-logo="showUrbeLogo"
                    :show-background="showNavBackground" 
                    :move-header-links="moveHeaderLinks" 
                    :user-access="authorization"
                    :is-loading="isLoading">
                </BaseHeader>
            </template>
    
            <div v-else class="fixed w-full z-[9999]">
                <MobileHeader :mobile-menu="showMobileMenu" @setMobileMenu="setMobileMenu" :isHome="true" />
                <MobileMenu @close="setMobileMenu" :show-mobile-menu="showMobileMenu" :isHome="true" :user-access="authorization" :mobile-app="mobileApp"/>
            </div>
        </template>

        <div :class="`${showMobileMenu ? 'hidden' : ''} ${$router.currentRoute.value.fullPath.includes('calculadora/rating') ? '' : 'overflow-x-hidden'}`">
            <RouterView v-slot="{ Component }">
                <component
                    :is="Component"
                    :userAccess="authorization"
                />
            </RouterView>

            <BaseFooter v-if="!mobileApp && showTemplate"/>
        </div>

        <Hijacker />
    </div>
</template>